.item_modal {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  #MUI_name {
    grid-column: 1 / 4;
  }
  #MUI_description {
    grid-column: 1 / 4;
  }
}
