@import '../../styles/colors';

.service_item {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_filterby {
      width: 240px;
      margin-left: 15px;
    }
  }
  &_color-cell {
    text-align: left;
    background-color: red !important;
    &_column {
      display: flex;
      align-items: center;
      width: 100%;
      &_wrap {
        padding: 3px;
        background: white;
        border: 2px solid $color-light;
        border-radius: 5px;
        display: inline-block, ¡;
        &_color {
          width: 32px;
          height: 20px;
          border-radius: 2px;
          border: 1px solid #ccc;
        }
      }
    }
  }
}
