@import '../../../styles/colors';

.statusmodal {
  display: grid;
  grid-template-columns: 4fr 1fr 2fr;
  align-items: flex-end;
  column-gap: 20px;
  &_color {
    &_label {
      color: $color-normal;
      font-size: 11px;
      padding: 0 0 2px 5px;
    }
  }
}
