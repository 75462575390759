.dashboard {
  width: fit-content;
  padding: 20px;
  display: flex;
  &_item {
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_title {
      margin-top: 10px;
    }
    &_value {
      font-size: 40px;
      border: 8px solid grey;
      border-radius: 80px;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
