@import '../../styles/colors';

.profile {
  width: 400px;
  &_title {
    font-size: 14px;
    margin-bottom: 20px;
  }
  &_logo {
    padding: 20px;
    margin-bottom: 40px;
    &_container {
      display: grid;
      grid-template-columns: 220px 1fr;
      grid-gap: 10px;
      align-items: center;
      &_area {
        border: 1px dashed $color-cancel;
        border-radius: 10px;
        padding: 5px;
        margin-right: 10px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
    }
  }
  &_logoPreview {
    max-width: 100%;
    max-height: 100%;
    display: block;
  }

  &_passwordChange {
    padding: 20px;
    &_form {
      display: grid;
      grid-row-gap: 20px;
      margin-bottom: 20px;
    }
  }

  &_active {
    cursor: pointer;
  }
}
