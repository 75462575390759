@import '../../styles/colors';

.tracking {
  padding: 20px;
  &_header {
    padding: 10px;
    background-color: $color-secondary;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    &_info {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      &_label {
        font-size: 10px;
      }
      &_space {
        margin-top: 5px;
      }
    }
    &_status {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &_label {
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }

  &_items {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid $color-secondary;
    // background-color: $color-secondary;
    &_item {
      width: 100%;
      display: grid;
      grid-template-columns: 100px 100px 200px;
      padding: 5px 0;
      font-size: 12px;
    }
    .header {
      font-weight: bolder;
      font-size: 14px;
    }
  }
}
